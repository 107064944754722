<template>
  <div class="hotspots2">    
    <!-- Default: "root" hotspots with "merged" data -->  
    <!-- Toggle feature: individual hotspots breakdown -->
    
    <!-- <input type="checkbox" id="breakdowncheckbox" v-model="hotspotBreakdown" style="vertical-align:middle; margin 0 0 0 7px;">
    <span style="vertical-align:middle;"> Breakdown per {{labelName ? labelName : 'scene'}}</span> -->

    <div v-for="hotspot in filteredHotspots" :key="hotspot.id" class="hotspot">
      <!-- {{hotspot.text}}
      <br>      
      <div v-for="item in hotspot.mergedItems" :key="item.hotspotId + item.id">
        {{item.text}}
      </div> -->
      <div class="chartTitle"  @click="toggle">{{hotspotBreakdown ? `${hotspot.text} - ` : ''}}Selections average ({{resMerged(hotspot.mergedIds, true).length}} Selection)</div>
      <div class="bubbleChartContainer">
        <div class="bubbleChart">
          <!-- <canvas ref="bubbles" id="bubbles"></canvas> -->
          <!-- <div v-for="(data, index) in bubbles(hotspot).data" :key="index" class="bubble" 
          :style="{width: bubbleSize(data.count, bubbles(hotspot).largestBubbleValue) + 'px', height: bubbleSize(data.count, bubbles(hotspot).largestBubbleValue) + 'px', backgroundColor: getRankColor(index, bubbles(hotspot).data.length)}"
          > -->
          
          <div v-for="(bubble, index) in bubbles(hotspot)" :key="index" class="bubble"
            :style="{width: bubble.size + 'px', height: bubble.size + 'px', backgroundColor: bubble.color}"
            :title="`Avg selection order: ${bubble.selectionOrderScore}`"
          > 
            <div>{{bubble.count}}</div>
            <!-- <div>{{bubble.title}}</div> -->
          </div>
          
          <div class="bubbleChartLegend">
            <span>First</span>
            <span>Selection Order (Avg)</span>
            <span>Last</span>
          </div> 
        </div>  
        <div class="sideLegend">
          <template v-for="(bubble, index) in bubbles(hotspot)" >
            <span :key="index+'span'" :style="{backgroundColor: bubble.color}"></span>{{bubble.title}}
            <br :key="index+'br'">
          </template>
        </div>          
      </div>    
    </div>
        
    <div class="chartTitle" @click="toggle">Over Time Selections</div>
    <div class="sceneChart" >
      <div class="sceneChartContainer">
        <div>
          <canvas ref="sceneChart"></canvas>
        </div>
      </div>
      <div class="sideLegend">
        <span></span>Number of Selections
        <br>
        <span></span>Estimated amount of time   
      </div>
    </div>
    
  </div>
</template>



<script>

//import groupBy from 'lodash/groupBy';
//import toArray from 'lodash/toArray';
//import chain from 'lodash/chain';
//import xor from 'lodash/xor';
import _ from 'lodash';
import chroma from 'chroma-js'
import Chart from 'chart.js/auto'
// import ChartDataLabels from 'chartjs-plugin-datalabels'

let sceneChart

export default {
  name: 'Hotspots',
  props: {
    hotspotBreakdown: Boolean,
    hotspots: Array,
    results: Array,
    segmentId: Number // segmentId only needed in case a Hotspot with the same Id is present in multiple conditions, so the component can then filter results only to the selected condition
  },
  data: function() {
    return {      
      
    }
  },
  computed: {
    filteredHotspots(){
      return this.hotspotBreakdown 
    ? this.hotspots.filter(h => h.segmentId == this.segmentId && !h.root).map(child => 
        ({
          ...child,
          mergedIds: [child.id],
          mergedItems: child.items.filter(i => i.countable).map(i => ({...i, hotspotId: child.id}))
        })
      )  
    : this.hotspots.filter(h => h.segmentId == this.segmentId && h.root).map(parent => 
        ({
          ...parent,
          mergedIds: this.hotspots.filter(h => h.parentId == parent.id).map(h => h.id),
          mergedItems: this.hotspots.filter(h => h.parentId == parent.id).map(h => h.items.filter(i => i.countable).map(i => ({...i, hotspotId: h.id}))).flat()
        })
      )   
    },
    orderedResults(){
      return _.sortBy(this.results, 'date')
    },
    sceneMetrics(){ // watch and redraw chart to refresh whenever metrics have changed
      return this.hotspots.filter(h => h.segmentId == this.segmentId).map(hotspot =>
        ({
          ...hotspot,
          metrics: this.results
            .filter(r => r.hotspot && r.questionId == hotspot.id && r.segmentId == this.segmentId)
            .reduce((metric, r) => {
              if(r.optionId)
                metric.count++
              if(r.time)  // TODO this counts every result that has "time" property. We might want to only count time metrics for hotspot (without optionId), but this is not yet decided whether we'll have per item time metrics
                metric.time += r.time
              return metric
            }, {time: 0, count: 0})
        })
      )
    }
  },
  methods: {
    toggle(ev){      
      ev.target.classList.toggle('collapsed');
    },
    getRankColor(rank, total/*, val*/){   
      // if(val == 0)
      //   return "#bfc5d1";   
      const f = chroma.scale([ '#d541e0', '#aa3fe0', '#753fe0', '#195dcf', '#199dcf', '#28beb9', '#19b271', '#83bc38', '#c9ce44', '#d99e50', '#d97550' ]);
      //console.log(f(0.25).toString())
      return f(rank/total).toString()
      //return `rgba(31, 113, 188, ${(rank/20)*0.77})`
    },
    bubbles(hotspot){
      // need to order by timestamp and group by users to count average of nth selection + also aggregate count per item + find maxcount
      let dataByUsers = _(this.resMerged(hotspot.mergedIds)).groupBy('userId').map((value, key) => ({userId: key, interactions: value})).value()
      //console.log(dataByUsers)
      let data = hotspot.mergedItems.map(item => {
        let firstSelectionIndexes = dataByUsers.map(x => (x.interactions.findIndex(i => i.optionId == item.optionId && i.questionId == item.hotspotId) + 1))//.filter(i => i > 0)  // aka hányadikra választották egyes userek == first found index per user in results ordered by time
        //console.log(item.hotspotId + '_' + item.optionId)
        //console.log(firstSelectionIndexes)
        let averageSelectionOrder = firstSelectionIndexes.length ? _.mean(firstSelectionIndexes) : 0
        //console.log(averageSelectionOrder)
        
        let count = this.res(item.hotspotId, item.optionId).length
        
        return ({
          title: item.text,
          count: count,
          selectionOrderScore: averageSelectionOrder
        })
      })

      let largestBubbleValue = _.max(data.map(d => d.count))
      
      let tmp = _.sortBy(data,'selectionOrderScore').map((d,index) => ({...d, size: this.bubbleSize(d.count, largestBubbleValue), color: this.getRankColor(index, data.length, d.count)}))
      //console.log(tmp)
      return [...tmp.filter(t => t.count), ...tmp.filter(t => !t.count)]
    },
    bubbleSize(count, largestBubbleValue){
      var maxRadius = 150/2.2 // a little less than half of the chart height
      var minRadius = 20 // so labels will fit
      //return value.count / context.dataset.largestBubbleValue * maxRadius
      return (largestBubbleValue == 0 ? minRadius : Math.round(minRadius + (maxRadius-minRadius)*count/largestBubbleValue))*2
    },
    res(hotspotId, itemId){
      //console.log(hotspotId, itemId)
      return this.orderedResults.filter(r => 
        r.hotspot
        && r.questionId == hotspotId
        && (!itemId || r.optionId == itemId)
        && r.segmentId == this.segmentId)     // assuming segmentId always updated from outside component
    },
    resMerged(mergedIds, onlyCountWithOptions){
      return this.orderedResults.filter(r => r.hotspot && mergedIds.includes(r.questionId) && (!onlyCountWithOptions || r.optionId) && r.segmentId == this.segmentId)
    },
    initSceneChart(){
      sceneChart?.destroy()

      const chartAreaBorder = {
        id: 'chartAreaBorder',
        beforeDraw(chart, args, options) {
          const {ctx, chartArea: {left, top, width, height}} = chart;
          ctx.save();
          ctx.strokeStyle = options.borderColor;
          ctx.lineWidth = options.borderWidth;
          ctx.setLineDash(options.borderDash || []);
          ctx.lineDashOffset = options.borderDashOffset;
          //ctx.strokeRect(left, top, width, height);
          ctx.moveTo(left, top);
          ctx.lineTo(left, top + height)
          //ctx.stroke()
          //ctx.moveTo(left, top + height);
          ctx.lineTo(left + width, top + height)
          ctx.lineTo(left + width, top)
          ctx.stroke()
          ctx.restore()
        }
      };
      const mixedChart = new Chart(this.$refs.sceneChart, {
        data: {
          datasets: [{
              type: 'bar',
              label: '  Estimated amount of time          ',
              pointStyle: 'rect',
              data: this.sceneMetrics.map(m => m.metrics.time),
              barThickness: 40,
              borderSkipped: true,
              backgroundColor: 'rgba(0, 159, 111, 0.3)',
              yAxisID: 'seconds',
              tooltip: {
                  callbacks: {
                      label: function(context) {
                          return context.parsed.y + ' seconds overall'
                      }
                  }
              }
          }, 
          {
              type: 'line',
              label: '  Number of Selections',
              pointStyle: 'circle',
              data: this.sceneMetrics.map(m => m.metrics.count),
              yAxisID: 'count',
              showLine: false,
              pointRadius: 6,
              pointHoverRadius: 10,
              pointBackgroundColor: '#009f6f',
              borderWidth: 0,
              tooltip: {
                  callbacks: {
                      label: function(context) {
                          return context.parsed.y + ' selections'
                      }
                  }
              }
          }],
          labels: this.sceneMetrics.map(m => m.text)
        },
        plugins: [chartAreaBorder],
        options: {  
          //responsive: false,      
          maintainAspectRatio: false,
          // layout: {
          //     padding: {
          //         left: 56,
          //         right: 0
          //     },
          //     autoPadding: false
          // },
          plugins: {
              // legend: {
              //     //display: false
              //   position: 'bottom',
              //   labels: {
              //     boxWidth: 16,
              //     boxHeight: 16,
              //     userPointStyle: true
              //     //padding: 40
              //   }
              // },
              legend: {
                display: false,
                position: 'bottom',
                labels: {
                  font: {
                    size: 16
                  },
                  padding: 15,
                  //pointStyleWidth: 16,
                  usePointStyle: true,
                },
              },
              chartAreaBorder: {
                borderColor: '#c1cade',
                borderWidth: 2,
                //borderDash: [5, 5],
                //borderDashOffset: 2,
              }
          },
          scales: {
            x: {
              ticks: {
                font: {
                  size: 16,
                }
              },
              grid: {
                display: false
              }  
            },
            seconds: {
              type: 'linear',
              position: 'left',
              ticks: {
                font: {
                  size: 14,
                }
              },
              title: {
                text: 'Time (seconds)',
                display: true,
                font: {
                  size: 16,
                }
              },
              grid: {
                display: false
              },
              afterFit: function(axis) {
                  axis.width = 60;
                  //console.log(axis);
              }        
            },
            count: {
              type: 'linear',
              position: 'right',
              ticks: {
                font: {
                  size: 14,
                }
              },
              title: {
                text: 'No. of Selections',
                display: true,
                font: {
                  size: 16,
                }
              },
              grid: {
                display: false
              },
              afterFit: function(axis) {
                  axis.width = 60;
                  //console.log(axis);
              }        
              // ticks: {
              //     max: 1,
              //     min: 0
              // }
            }
          }
        }
      })

      sceneChart = mixedChart
    }
  },
  watch: {
    sceneMetrics: function (/*newItem, oldItem*/) {
      //console.log(sceneChart.data)
      if(sceneChart){
        sceneChart.data.datasets[0].data = this.sceneMetrics.map(m => m.metrics.time)
        sceneChart.data.datasets[1].data = this.sceneMetrics.map(m => m.metrics.count)
        sceneChart.update()  
      }   
    },
    hotspotBreakdown: function(){
      //sceneChart?.destroy()
      // this.$refs.sceneChart.width
      //setTimeout(() => this.initSceneChart(), 300 ) // to adjust chart and canvas width, and timing to allow layout to resize, otherwise the fixed canvas stretches everything
      //sceneChart?.resize()
    }
  },
  mounted(){
    this.initSceneChart()

    // const myChart = new Chart(this.$refs.bubbles, {
    //     type: 'bubble',
    //     data: {
    //         //labels: ['bubble1','bubble2'],
    //         datasets: [{
    //             //label: '# of Votes',
    //             largestBubbleValue: 25, // TODO this is just temporarily added manually, will need to
    //             data: [{
    //               x: 20,
    //               y: 30,
    //               // r:50,
    //               title: 'Windscreen Cleaner',
    //               count: '5'
    //             }, {
    //               x: 40,
    //               y: 10,
    //               // r: 30,
    //               title: 'omggg',
    //               count: '20'
    //             }],
    //             backgroundColor: [
    //                 'rgba(255, 99, 132, 0.2)',
    //                 'rgba(54, 162, 235, 0.2)'
    //             ],
    //             // borderColor: [
    //             //     'rgba(255, 99, 132, 1)',
    //             //     'rgba(54, 162, 235, 1)'
    //             // ],
    //             borderWidth: 0
    //         }]
    //     },
    //     plugins: [ChartDataLabels],
    //     options: {
    //         scales: {
    //             y: {
    //                 beginAtZero: true,
    //                 display: false
    //             },
    //             x: {
    //               display: false //this will remove all the x-axis grid lines
    //             }                
    //         },
    //         maintainAspectRatio: false,
    //         layout: {
    //             // padding: {
    //             //     left: 40,
    //             //     right: 20
    //             // },
    //             autoPadding: true
    //         },
    //         plugins: {
    //           legend: {
    //             display: false
    //           },
    //           datalabels: {
    //             color: 'blue',
    //             labels: {
    //               title: {
    //                 font: {
    //                   weight: '600',
    //                   size: '14px'
    //                 },
    //                 color: "#323f5b",
    //                 formatter: function(value, ctx) {
    //                   // return ctx.chart.data.labels[ctx.dataIndex];
    //                   return ctx.dataset.data[ctx.dataIndex].title
    //                 },
    //                 anchor: 'center',
    //                 //offset: 10,
    //                 align: 'bottom'
    //               },
    //               count:{
    //                 font: {
    //                   weight: '600',
    //                   size: '30px'
    //                 },
    //                 color: "#323f5b",
    //                 formatter: function(value, ctx) {
    //                   // return ctx.chart.data.labels[ctx.dataIndex];
    //                   return ctx.dataset.data[ctx.dataIndex].count
    //                 },
    //                 anchor: 'center',
    //                 offset: -15,
    //                 align: 'top'
    //               }
    //             }
    //           }
    //         },
    //         elements: {
    //         point: {
    //           radius: function(context) {
    //             var value = context.dataset.data[context.dataIndex]
    //             var maxRadius = 130/2.2 // a little less than half of the chart height
    //             var minRadius = 30 // so labels will fit
    //             //return value.count / context.dataset.largestBubbleValue * maxRadius
    //             return minRadius + (maxRadius-minRadius)*value.count/context.dataset.largestBubbleValue
    //           }
    //         }
    //       }
    //     }
    // })

    // this.bubbleChart = myChart
  }
}
</script>



<style lang="scss">

.hotspots2{

  .hotspot{
    border-bottom: $bordermixin;
  }

  .chartTitle{
    padding: 17px 100px 17px 40px;
    //background-color: #F8F9FB;
    color: $december;
    font-size: 16px;
    font-weight: bold;
    padding-right: 100px;
    cursor: pointer;
    position: relative;

    &:after{
      content: '\276E';
      position: absolute;
      font-size: 18px;
      left: 10px;
      top: 30%;
      color: #00684b;
      opacity: 0.5;
      transform: rotate(90deg);
      transition: transform 0.5s ease;      
    }

    &:hover:after{
      opacity: 1;
    }

    &.collapsed{
      overflow: hidden;
      & + .bubbleChartContainer, & + .sceneChart, & + .bubbleChartContainer + .bubbleChartLegend{
        max-height: 0px !important;
        padding-bottom: 0px;
        padding-top: 0px;
        overflow: hidden;               
        transform-origin: top;  
        transform: scaleY(0); 
        margin-top: 0;
        margin-bottom: 0;
      }

      &:after{
        transform: rotate(270deg);
      }
    }
  }

  hr{
    height: 0px;
    opacity: 0.35;
    border: solid 1px #c1cade;
    margin: 8px 0 24px 0;
  }

  .bubbleChartContainer{
    display: flex;

    .sideLegend{
      width: 30%;
    }
  }

  .bubbleChart{
    flex-grow: 1;
    border-left: 1px solid #c1cade70;
    border-bottom: 1px solid #c1cade70;
    padding: 12px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin-left: 60px;
    margin-right: 60px;
    margin-top: 32px;
    margin-bottom: 50px;
    position: relative;
    width: 70%;

    .bubble{
      border-radius: 500px;      
      display: flex;      
      justify-content: center;
      align-content: center;
      flex-direction: column;
      text-align: center;
      background-color: greenyellow;
      font-weight: 600;      
      user-select: none;
      color: white;

      transition: all 0.5s ease;

      div:first-child{
        font-size: 30px;
        //margin-top: 20px;
      }
      // div:last-child{
      //   font-size: 14px;
      //   line-height: 1.43;
      // }
    }

    canvas{
      height: 132px;
    }
  }

  .bubbleChartLegend{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #323f5b;
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 60px;
    
    span{
      display: block;
    }
  }

  .sideLegend{
      //width: 230px;
      //width: 30%;
      padding-left: 16px;
      line-height: 1.5;
      color: #7a8391;
      font-weight: 600;

      span{
        display: inline-block;
        margin-right: 8px;
        width: 12px;
        height: 12px;
        border-radius: 8px;
        background-color: #009f6f;
      }
    }

  .sceneChart{
    margin-top: 32px;
    position: relative;
    height: 240px;
    display: flex;

    .sceneChartContainer {      
      position: relative;
      flex-grow: 1;

      > div{  // chart.js canvas responsive "trick"
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    .sideLegend {
      span:last-child{
        border-radius: 2px;
        background-color: rgba(0, 159, 111, 0.3);
      }
    }

    canvas{
      height: 240px;
    }
  }
}

</style>
