<template>
  <li>
    <div
      :class="{folder: isFolder, opened: isOpen, selected: selectedItem == item || (selectedItem.condition && selectedItem.parent == item), newer: newer}"
      :style="{paddingLeft: (isFolder ? 40 : 0) + (level*40)+'px', backgroundPositionX: (level*40)+'px'}"
      :title="newer ? newer : ''"
      @click="toggle">
      
      <div class="thumbnail">
        <img v-if="!isFolder"  :src="contentRoot + item.icon" />
        <img v-if="played" class="played" src="../assets/approved.svg" />
      </div>
      <div class="title">{{ editorMode ? (item.type == 'm' ? item.name : (parsedStructure ? parsedStructure.txt1 : '???')) : item.txt1 }}</div>
    </div>
    <ul v-show="isOpen" v-if="isFolder" class="tree">
      <TreeItem2
        class="treeitem2"
        v-for="(child, index) in (editorMode ? item.children : item.list)"
        :editorMode="editorMode"
        :publishDate="publishDate"
        @select="selectHandler"
        @opened="openedHandler"
        :key="(editorMode ? (`${level}_${index}_${item.id}`) : `${level}_${index}`)"
        :item="child"
        :selectedItem="selectedItem"
        :parent = item
        :level="level + 1"
        :contentRoot="contentRoot"
        :metadata="metadata"
        :expandAll="expandAll"
      ></TreeItem2>      
    </ul>
  </li>
</template>



<script>

export default {
  name: 'TreeItem2',
  props: {
    item: Object,
    level: Number,
    selectedItem: Object,
    parent: Object,
    metadata: Object,
    editorMode: Boolean,  // true when used for authoring, where we deal with content blocks with embedded structure. false when fed by the json structure directly.
    publishDate: String,
    contentRoot: String,
    expandAll: Boolean
  },
  data: function() {
    return {
      isOpen: false,
      //selected: false
    };
  },
  computed: {
    played(){
      return !this.isFolder 
      && this.metadata?.playedSegmentIds
      && 
      ( 
        (this.item.type == 'seg' && this.metadata.playedSegmentIds.includes(this.item.id)) 
        || 
        (this.item.type == 'conditional' && this.item.list.find(s => s.type == 'seg' && this.metadata.playedSegmentIds.includes(s.id))) 
      )
    },
    isFolder: function() {
      return this.editorMode
      ? this.item.type == 'm' || this.item.type == 'f'
      : this.item.type == 'list' && this.item.list && this.item.list.length;
    },
    parsedStructure(){
      return this.editorMode ? JSON.parse(this.item.structure) : null
    },
    newer(){
      //if(this.editorMode && (this.item.published > this.publishDate /*|| !this.publishDate*/)){
      if(this.publishDate && this.item.published && (this.item.published > this.publishDate)){
        const itemPublished = new Date(this.item.published)
        const diffTime = Math.abs(new Date() - itemPublished);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        return `Newer version exists since ${itemPublished.toLocaleString()} (${diffDays} days ago)`
      }
      return null
    }
  },
  methods: {
    toggle: function() {
      if (this.isFolder) {
        //if(!(this.isOpen && this.item != this.selectedItem) || this.editorMode)
          this.isOpen = !this.isOpen;
      }
      // this.selected = true
      // console.log(this.selected)
      else
        this.$emit("select", this.item)
    },
    selectHandler(event){  
      this.$emit('select', event)
    },    
    openedHandler(){
      this.isOpen = true;
      this.$emit('opened')
    }
    // makeFolder: function() {
    //   if (!this.isFolder) {
    //     this.$emit("make-folder", this.item);
    //     this.isOpen = true;
    //   }
    // }
  },
  watch: {
    // selectedItem: function(newVal, oldVal) { // watch it
    //     console.log('Prop changed: ', newVal, ' | was: ', oldVal)
    //   }
    selectedItem(newItem) {
      if(/*this.isFolder &&*/ this.item == newItem){
        this.isOpen = true
        this.$emit('opened')
        // console.log(newItem.txt1)
      }
    },
    expandAll(newItem){
      this.isOpen = newItem
    }
  },
  mounted(){
    if(this.expandAll)
      this.isOpen = true
  }
}
</script>



<style lang="scss">
ul.tree{
  list-style-type: none;
  padding: 0;
}

.treeitem2{
  min-height: 32px;  

  .thumbnail{
    position: relative;

    img{
      width: 162px;
      height: 100px;
      box-sizing: border-box;
      border-radius: 4px;
      margin-bottom: 5px;
      margin-top: 8px;
    }

    img.played{
      position: absolute;
      left: 135px;
      top: 75px;
      width:18px;
      height:18px;
    }
  }

  > div {
    padding: 6px 0;
    cursor: pointer;
    line-height: 16px;

    .title{
      line-height: 1.2;
      max-width: 200px;
    }

    &.selected{
      background-color: #EFF2F4;    

      .thumbnail img:not(.played){
        opacity: 1;
        border: solid 3px #4cad82;
      }
    }

    &:hover{
      background-color: #EFF2F4;
      &:active{
        background-color: #E9F4EA;
      }
    }

    &.newer{
      color: $red;
    }    
  }

  .folder{    
    margin-bottom: 25px;   
    margin-top: 20px;   
    padding-left: 40px;
    min-height: 32px;
    position: relative;

    &:before{
      content: ' ';
      position: absolute;
      width: 20px;
      height: 20px;
      background-size: contain;
      left: 0;
      top: 8px;
      background-image: url("../assets/folder_close.svg");
      background-repeat: no-repeat;
    }

    > .title{
      font-size: 18px;
      line-height: 1.3;
      max-width: 210px;
    }

    &.opened{
      &:before{
        background-image: url("../assets/folder_open.svg");
      }      
      margin-bottom: 0;   
      
      > .title{
        font-weight: bold;
      }
    }
    
  }
}
</style>
